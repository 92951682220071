import React, { useEffect, useState } from "react";
import {Input, Typography, Button, Spin, Form, Switch, ColorPicker, notification, Select} from "antd";
import { get, post } from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const { Title } = Typography;

const AgentSettings = () => {
    const [fromLoading, setFromLoading] = useState(false);
    const navigate = useNavigate();

    const [inputsValue, setInputsValue] = useState({});
    const [initValues, setInitValues] = useState({});
    const [optionPackages, setOptionPackages] = useState([]);

    useEffect(() => {
        get("agent-settings").then((res) => {
            setInputsValue(res.data);
            setFromLoading(true);
            const initValuesNew = {};
            res.data.map((item) => {
                if (item.key == 'private_property_package') {
                    initValuesNew[item.key] = item.value.split(',').map(Number);
                }
                else {
                    initValuesNew[item.key] = item.value;
                }
                return item;
            });
            setInitValues(initValuesNew);

            get("get-agent-packages").then((res) => {
                const initValuesNew = [];
                res.data.map((item) => {
                    initValuesNew.push({ value: item.id, label: item.name_en});
                    return item;
                });
                setOptionPackages(initValuesNew);
            });
        });
    }, []);

    const [loading, setLoading] = useState(false);

    const handlePicker = (color, name) => {
        initValues[name] = color.toHexString();
    };

    const onFinish = (values) => {
        setLoading(true);

        const newArr = [];
        Object.keys(values).forEach(function (key, index) {
            const newObj = {};
            newObj.key = key;
            newObj.value = values[key];
            newArr.push(newObj);
        });

        post("agent-settings", { settings: newArr })
            .then((res) => {
                notification.success({ message: "Modified successfully" });
                setLoading(false);
                navigate("/agent-settings");

            })
            .catch((err) => {
                notification.error({
                    message: "Error!",
                    description: err.response.data.message,
                });
                setLoading(false);
            });
    };

    return (
        <div>
            <Title level={2} className="text-main mb-2">
                Agent Settings
            </Title>
            {fromLoading ? (
                <Form
                    className="wrapper-content"
                    onFinish={onFinish}
                    layout="vertical"
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 15,
                    }}
                    initialValues={
                        initValues
                    }
                    autoComplete="off"
                >
                    <div className="row">
                        {
                            inputsValue.map((item) => (
                                <>
                                    <div className="col-lg-6">
                                        <Form.Item
                                            label={item.name}
                                            name={item.key}
                                            rules={[
                                                {
                                                    required: item.type !== "checkbox" ? true : false,
                                                    message: "This field is required!",
                                                },
                                            ]}
                                            extra={(item.key == 'starting_zoom_level' || item.key == 'apply_max_at_zoom_level') ? 'Zoom level must be between 7 and 14' : ' '}
                                        >
                                            {item.type == "checkbox" ? (
                                                <>
                                                    <Switch defaultChecked={item.value} />
                                                </>
                                            ) : item.type == "textarea" ? (
                                                <>
                                                    <ReactQuill theme="snow" value={item.value} />
                                                </>
                                            ) : item.type == "color" ? (
                                                <>
                                                    <ColorPicker
                                                        defaultValue="#0277bd"
                                                        onChange={(color) =>
                                                            handlePicker(color, item.key)
                                                        }
                                                        size="large"
                                                    />
                                                </>
                                            ) : item.type == "select"  ? (
                                                <>
                                                    <Select
                                                        mode="multiple"
                                                        allowClear
                                                        showSearch
                                                        className="w-100"
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? "")
                                                                .toLowerCase()
                                                                .includes(input.toLowerCase())
                                                        }
                                                        placeholder="Select"
                                                        options={optionPackages}
                                                />
                                                </>
                                                ): (
                                                <>
                                                    <Input placeholder="Type..." />
                                                </>
                                            )}
                                        </Form.Item>
                                    </div>
                                </>
                            ))
                        }
                    </div>
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading}
                                    size="large"
                                    block
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {loading ? "Saving..." : "Save"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            ) : (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};

export default AgentSettings;
