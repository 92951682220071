import React, { useState, useEffect } from "react";
import {Upload, Modal, notification, Progress, Form} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { postFromData } from "../../../api/axios";

const ImagesUploadComponent = ({ onImageUpload, imageList, setImageList, storagePath }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    // Ensure each preloaded image has a unique `uid`, `url`, and `name`
    useEffect(() => {
        setImageList(prevList =>
            prevList.map((image, index) => ({
                ...image,
                uid: image.uid || `${index}-${new Date().getTime()}`, // Ensure unique UID
                url: image.path || `${process.env.REACT_APP_BASE_IMAGE_URL}${image.path}`, // Ensure URL is correct
                path: image.url || '', // Ensure path is set
                name: image.name || `Image-${index + 1}`, // Set a name if missing
            }))
        );
    }, [setImageList]);

    const handlePreview = (file) => {
        console.log(file);
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleBeforeUpload = async (file) => {
        setUploading(true);
        let simulatedProgress = 0;
        const progressInterval = setInterval(() => {
            simulatedProgress += 5; // Increase progress by 5% every interval
            setProgress(Math.min(simulatedProgress, 95)); // Cap progress at 95% to await actual completion
        }, 200);
        try {
            const response = await postFromData('/upload-image', { 'image': file, 'store_path': storagePath });
            clearInterval(progressInterval); // Clear progress interval on completion
            setProgress(100); // Set progress to 100% on success

            const imagePath = response.data;
            file.url = process.env.REACT_APP_BASE_IMAGE_URL + imagePath;
            file.path = imagePath;
            setImageList((prev) => [...prev, {
                ...file,
                uid: file.uid || `${file.url.substring(file.url.lastIndexOf('/') + 1)}-${new Date().getTime()}`
            }]);
            onImageUpload(imagePath);

            notification.success({
                message: "Upload Successful",
                description: `${file.name} uploaded successfully!`,
            });
        } catch (error) {
            notification.error({
                message: "Error!",
                description: "Image upload failed.",
            });
        } finally {
            setUploading(false);
            setProgress(0);
        }
        return false;
    };

    const handleChange = ({ fileList }) => {
        setImageList(fileList);
    };

    const handleRemove = (file) => {
        setImageList(imageList.filter(item => item.uid !== file.uid));
    };

    return (
        <>
            <Form.Item label="images" className="mb-0" />
            <Upload
                listType="picture-card"
                fileList={imageList}
                onPreview={handlePreview}
                onChange={handleChange}
                beforeUpload={handleBeforeUpload}
                onRemove={handleRemove}
                accept=".png,.jpg,.jpeg,.svg"
            >
                {imageList.length >= 12 ? null : <div><PlusOutlined /><div>Upload</div></div>}
            </Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
                <img alt="preview" style={{ width: '100%' }} src={previewImage} />
            </Modal>
            {uploading && <Progress percent={progress} />}
        </>
    );
};

export default ImagesUploadComponent;
