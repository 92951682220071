import {Button, Divider, Form, Input, InputNumber, Select} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import Title from "antd/es/skeleton/Title";

const UnitsComponent = () => {
   return (
       <Form.List name="units">
           {(fields, { add, remove }) => (
               <>
                   {fields.map(({ key, name, ...restField }) => (
                       <div key={`units-${key}`}>
                           <div className="d-flex">
                               <Form.Item
                                   label='Number'
                                   className='w-100 mb-1'
                                   {...restField}
                                   name={[name, 'number']}
                                   rules={[
                                       {
                                           required: true,
                                           message: "This field is required!",
                                       },
                                   ]}
                               >
                                   <Input className='w-100' placeholder="Type..."/>
                               </Form.Item>
                               <Form.Item
                                   label='Price'
                                   className='w-100 mb-1'
                                   {...restField}
                                   name={[name, 'price']}
                                   rules={[
                                       {
                                           required: true,
                                           message: "This field is required!",
                                       },
                                   ]}
                               >
                                   <Input className='w-100' type="number" placeholder="Type..."/>
                               </Form.Item>
                           </div>

                           <div className="d-flex">
                               <Form.Item
                                   label='Status'
                                   className='w-100 mb-1'
                                   {...restField}
                                   name={[name, 'status']}
                                   rules={[
                                       {
                                           required: true,
                                           message: "This field is required!",
                                       },
                                   ]}
                               >
                                   <Select
                                       optionFilterProp="children"
                                       className="w-100"
                                       filterOption={(input, option) =>
                                           (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                       }
                                       placeholder="Select"
                                       options={[
                                           {label: 'Available', value: 'available'},
                                           {label: 'Reserved', value: 'reserved'},
                                           {label: 'Sold', value: 'sold'},
                                       ]}
                                   />
                               </Form.Item>
                               <Form.Item
                                   label='Area'
                                   className='w-100 mb-1'
                                   {...restField}
                                   name={[name, 'area']}
                                   rules={[
                                       {
                                           required: true,
                                           message: "This field is required!",
                                       },
                                   ]}
                               >
                                   <Input className='w-100' placeholder="Type..."/>
                               </Form.Item>
                           </div>
                           <div className="d-flex">
                               <Form.Item
                                   label='Order'
                                   className='w-50 mb-1'
                                   {...restField}
                                   name={[name, 'order']}
                                   rules={[
                                       {
                                           required: true,
                                           message: "This field is required!",
                                       },
                                   ]}
                               >
                                   <InputNumber className='w-100' min={0}/>
                               </Form.Item>
                           </div>
                           <div className="px-4">
                               <Title level={4} className="mt-3"> Fields </Title>
                               <Form.List name={[name, 'custom_fields']}>
                                   {(customFields, {add, remove}) => (
                                       <>
                                           {customFields.map(({key: customKey, name: customName, ...customRestField}) => (
                                               <div key={`custom-${key}-${customKey}`}>
                                                   <div className="d-flex">
                                                       <Form.Item
                                                           label='Name Ar'
                                                           className='w-100 mb-1'
                                                           {...customRestField}
                                                           name={[customName, 'name_ar']}
                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: "This field is required!",
                                                               },
                                                           ]}
                                                       >
                                                           <Input className='w-100' placeholder="Type..."/>
                                                       </Form.Item>
                                                       <Form.Item
                                                           label='Name En'
                                                           className='w-100 mb-1'
                                                           {...customRestField}
                                                           name={[customName, 'name_en']}
                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: "This field is required!",
                                                               },
                                                           ]}
                                                       >
                                                           <Input className='w-100' placeholder="Type..."/>
                                                       </Form.Item>
                                                   </div>
                                                   <div className="d-flex">
                                                       <Form.Item
                                                           label='Value Ar'
                                                           className='w-100 mb-1'
                                                           {...customRestField}
                                                           name={[customName, 'value_ar']}
                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: "This field is required!",
                                                               },
                                                           ]}
                                                       >
                                                           <Input className='w-100' placeholder="Type..."/>
                                                       </Form.Item>
                                                       <Form.Item
                                                           label='Value En'
                                                           className='w-100 mb-1'
                                                           {...customRestField}
                                                           name={[customName, 'value_en']}
                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: "This field is required!",
                                                               },
                                                           ]}
                                                       >
                                                           <Input className='w-100' placeholder="Type..."/>
                                                       </Form.Item>
                                                   </div>
                                                   <div className="d-flex">
                                                       <Form.Item
                                                           label='Order'
                                                           className='w-100 mb-1'
                                                           {...customRestField}
                                                           name={[customName, 'order']}
                                                           rules={[
                                                               {
                                                                   required: true,
                                                                   message: "This field is required!",
                                                               },
                                                           ]}
                                                       >
                                                           <Input type="number" className='w-100' placeholder="Type..."/>
                                                       </Form.Item>
                                                   </div>
                                                   <MinusCircleOutlined className='ms-1' onClick={() => remove(customName)}/>
                                                   <Divider/>
                                               </div>
                                           ))}
                                           <div className='d-flex justify-content-end'>
                                               <Button className='text-main' type="link" onClick={() => add()}
                                                       icon={<PlusOutlined/>}>
                                                   Add field
                                               </Button>
                                           </div>
                                       </>
                                   )}
                               </Form.List>
                           </div>
                           <MinusCircleOutlined className='ms-1' onClick={() => remove(name)}/>
                           <Divider/>
                       </div>
                   ))}
                   <div className='d-flex justify-content-end'>
                       <Button className='text-main' type="link" onClick={() => add()} icon={<PlusOutlined/>}>
                           Add Unit
                       </Button>
                   </div>
               </>
           )}
       </Form.List>
   );
};
export default UnitsComponent;
